import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

type FormattedQuestion = {
  '@type': string
  name: GatsbyTypes.Maybe<string>
  acceptedAnswer: {
    '@type': string
    text: GatsbyTypes.Maybe<string>
  }
}

export const convertFaqToJsonld = (
  faqQuestions: GatsbyTypes.ContentfulFaqSection['questions']
): GatsbyTypes.Maybe<FormattedQuestion[]> =>
  faqQuestions?.map((question) => {
    const text = question?.richContent?.raw
      ? documentToHtmlString(JSON.parse(question.richContent.raw))
      : ''

    const formattedQuestion = {
      '@type': 'Question',
      name: question?.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text,
      },
    }
    return formattedQuestion
  })

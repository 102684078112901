import React from 'react'
import styled, { css } from 'styled-components'
import { Grid } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import { PRODUCTS_MAP } from 'lib/products/constants'
import { GENERAL_TELEPHONE_NUMBER } from 'config'
import JSONLD from 'components/JSONLD'
import { convertFaqToJsonld } from 'lib/json-ld/convertFaqToJsonld'

import CallUsTodayCta from 'components/CallUsTodayCta'
import QuestionList from './QuestionList'
import OtherQuestions from './OtherQuestions'
import Heading, { HeadingProps } from './Heading'
import Illustration, { IllustrationProps } from './Illustration'

const StyledGrid = styled(Grid)`
  grid-template-rows: max-content;
`

const StyledImageGridItem = styled(Grid.Item)`
  align-items: center;
  margin: auto 0px;
`

const StyledOtherQuestionsWrapper = styled(Grid.Item)<{
  $transform: boolean
}>`
  ${({ $transform }) =>
    $transform &&
    css`
      ${screenMin.l`
        transform: translateY(-20px);
      `}
    `}
`

export type Products = keyof typeof PRODUCTS_MAP

export type Layout = 'one-col' | 'two-col'

interface FrequentlyAskedQuestionsProps {
  contextHeading?: HeadingProps['contextHeading']
  customPhoneNumber?: GatsbyTypes.ContentfulLocalFuneralPage['phoneNumber']
  image?: string
  imageBackground?: IllustrationProps['imageBackground']
  imageDecorativeSquiggle?: IllustrationProps['imageDecorativeSquiggle']
  customImageComponent?: React.ReactNode
  layout?: Layout
  preventSwapping?: boolean
  questions: GatsbyTypes.ContentfulFaqSection['questions']
  relatedGuide?: GatsbyTypes.ContentfulFaqSection['relatedGuide']
  selectedProduct?: Products
  telephoneWillsEnabled?: boolean
  title: HeadingProps['title']
  showCallUsTodayCta?: boolean
  ctaSubtitle?: string
  /**
   * The design for the wills A/B landing page experiment Q3 2023 has different
   * content and layout for the HereToHelp component. As we are testing this
   * design and it may not perform well, I have chosen not to expand the existing
   * component's configuration options for the new design. This prop should
   * ideally not be used for other purposes so that we can retire it when the A/B
   * experiment is complete and we can make a decision on whether to update the
   * design of the HereToHelp component.
   */
  customCta?: React.ReactElement
  customAmount?: number
}

const getTelephoneNumber = (selectedProduct?: Products): string => {
  if (!selectedProduct) {
    return GENERAL_TELEPHONE_NUMBER
  }

  return PRODUCTS_MAP[selectedProduct]
    ? PRODUCTS_MAP[selectedProduct].telephoneNumber
    : GENERAL_TELEPHONE_NUMBER
}

const FrequentlyAskedQuestions = ({
  contextHeading,
  customPhoneNumber,
  image = 'illustrations/blob-bird-binoculars',
  imageBackground = false,
  imageDecorativeSquiggle = false,
  customImageComponent,
  layout = 'one-col',
  preventSwapping = false,
  questions,
  relatedGuide,
  selectedProduct,
  telephoneWillsEnabled = true,
  title,
  showCallUsTodayCta = false,
  ctaSubtitle,
  customCta,
  customAmount,
}: FrequentlyAskedQuestionsProps): React.ReactElement => {
  const telephoneNumber =
    customPhoneNumber ?? getTelephoneNumber(selectedProduct)
  const faqContent = convertFaqToJsonld(questions)

  return (
    <>
      <JSONLD
        data={{
          '@context': 'http://schema.org',
          '@type': 'FAQPage',
          name: title,
          mainEntity: faqContent,
        }}
      />

      <StyledGrid
        gapFromXL={layout === 'one-col' ? `0 ${GTR.L}` : `0 ${GTR.XXL}`}
      >
        {layout === 'one-col' && (
          <>
            <Grid.Item
              span={12}
              spanFromL={6}
              spanRowsFromL={2}
              style={{ order: 2, zIndex: 2 }}
            >
              <Heading contextHeading={contextHeading} title={title} />
              <QuestionList
                questions={questions}
                hideAdditionalQuestions
                layout={layout}
                amount={customAmount || 3}
              />
              {showCallUsTodayCta &&
                selectedProduct &&
                (customCta || (
                  <CallUsTodayCta
                    product={selectedProduct}
                    subtitle={ctaSubtitle}
                    preventSwapping={preventSwapping}
                    customPhoneNumber={telephoneNumber}
                  />
                ))}
            </Grid.Item>
            <StyledImageGridItem
              span={showCallUsTodayCta ? 9 : 12}
              spanFromL={6}
              spanRows={showCallUsTodayCta ? 2 : 1}
              startRowFromL={1}
              startColumn={showCallUsTodayCta ? 2 : 1}
              startColumnFromL={7}
              style={{ order: 1 }}
              centered
            >
              {customImageComponent || (
                <Illustration
                  image={image}
                  imageBackground={imageBackground}
                  imageDecorativeSquiggle={imageDecorativeSquiggle}
                />
              )}
            </StyledImageGridItem>
          </>
        )}

        {layout === 'two-col' && (
          <>
            <Grid.Item
              span={12}
              spanFromL={6}
              spanFromXL={6}
              spanRowsFromL={1}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                order: 2,
                zIndex: 2,
              }}
            >
              <Heading contextHeading={contextHeading} title={title} />
            </Grid.Item>

            <Grid.Item
              span={12}
              spanFromL={6}
              spanFromXL={6}
              startRowFromL={1}
              startColumnFromL={7}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                order: 1,
              }}
              centered
            >
              {customImageComponent || (
                <Illustration
                  image={image}
                  imageBackground={imageBackground}
                  imageDecorativeSquiggle={imageDecorativeSquiggle}
                />
              )}
            </Grid.Item>
            <Grid.Item style={{ order: 3 }}>
              <QuestionList
                amount={customAmount || 10}
                questions={questions}
                hideAdditionalQuestions
                layout={layout}
              />
            </Grid.Item>
          </>
        )}

        <StyledOtherQuestionsWrapper
          span={12}
          spanFromL={6}
          spanFromXL={6}
          startRowFromL={layout === 'two-col' ? 3 : 2}
          startColumnFromL={layout === 'two-col' ? 1 : 7}
          style={{ order: 3 }}
          $transform={layout === 'one-col'}
        >
          {relatedGuide && telephoneWillsEnabled && (
            <OtherQuestions
              relatedGuide={relatedGuide}
              telephoneNumber={telephoneNumber}
              maxWidth={relatedGuide ? 6 : 4}
              preventSwapping={preventSwapping}
            />
          )}
        </StyledOtherQuestionsWrapper>
      </StyledGrid>
    </>
  )
}

export default FrequentlyAskedQuestions

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  Wrapper,
  P,
  H,
  Button,
  PhoneSolidIcon,
  ArrowRightIcon,
} from '@farewill/ui'
import { GTR, COLOR, FONT, BORDER } from '@farewill/ui/tokens'

import PATHS from 'paths'

import TelephoneNumber from 'components/TelephoneNumber'

const StyledOtherQuestions = styled(Wrapper)`
  border: solid 1px ${COLOR.GREY.LIGHT};
  border-radius: ${BORDER.RADIUS.S};
  background: ${COLOR.WHITE};
`

const StyledTitle = styled(H)`
  font-weight: ${FONT.WEIGHT.BOLD};
  color: ${COLOR.BLACK};
  margin-bottom: ${GTR.XS};
`

const StyledPhoneIcon = styled(PhoneSolidIcon)`
  vertical-align: middle;
  position: relative;
  top: -1px;
  color: ${COLOR.GREY.MEDIUM};
  margin-right: ${GTR.XS};
`

const StyledGuideLink = styled(Button.Bordered)`
  display: block;
  margin-top: ${GTR.S};
`

interface OtherQuestionsProps {
  telephoneNumber: string
  relatedGuide: GatsbyTypes.ContentfulFaqSection['relatedGuide']
  maxWidth: number
  preventSwapping?: boolean
}

const OtherQuestions = ({
  telephoneNumber,
  relatedGuide,
  maxWidth = 6,
  preventSwapping = false,
}: OtherQuestionsProps): React.ReactElement => (
  <StyledOtherQuestions padding={['S', 'M']} maxWidthInColumns={maxWidth}>
    <StyledTitle tag="h3">Got any other questions?</StyledTitle>
    <P>
      <StyledPhoneIcon size="M" />
      Call us on{' '}
      <TelephoneNumber
        underline
        telephoneNumber={telephoneNumber}
        showIcon={false}
        strong={false}
        preventSwapping={preventSwapping}
        bold
      />
      {relatedGuide && (
        <StyledGuideLink
          tag={Link}
          to={`${PATHS.GENERAL.CONTENT_HUB}/${relatedGuide?.slug}`}
          icon={<ArrowRightIcon size="M" />}
          stretch
        >
          Read our free guide
        </StyledGuideLink>
      )}
    </P>
  </StyledOtherQuestions>
)

export default OtherQuestions

import React from 'react'
import { H } from '@farewill/ui'

export interface HeadingProps {
  contextHeading?: string
  title: GatsbyTypes.ContentfulFaqSection['title']
}

const Heading = ({
  contextHeading,
  title,
}: HeadingProps): React.ReactElement => (
  <>
    {contextHeading ? <H context>{contextHeading}</H> : null}
    <H size="L" decorative>
      {title}
    </H>
  </>
)

export default Heading

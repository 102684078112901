import React from 'react'
import styled from 'styled-components'

import { Grid, Image } from '@farewill/ui'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'

const StyledIllustrationWrapper = styled(Grid.Item)`
  position: relative;

  ${screenMax.l`
    display: inline-block;
    margin: auto;
  `}

  ${screenMin.m`
    text-align: center;
  `}
`

const StyledIllustrationWrapperNoBackground = styled(Grid.Item)`
  position: relative;

  ${screenMax.m`
    display: inline-block;
    margin: auto;
  `}

  ${screenMin.m`
    text-align: center;
  `}
`

const StyledIllustration = styled(Image)`
  margin: 0 auto;
  position: relative;

  max-width: 350px;

  ${screenMin.l`
    max-width: 450px;
  `}
`

const StyledBlobIllustration = styled(Image)`
  position: absolute;
  inset: 0 0 auto;
  margin: auto;

  ${screenMax.s`
    width: 100%;
    inset: 0 0 auto;
  `}
`
const StyledDetailIllustration = styled(Image)`
  position: absolute;
  inset: 20% auto auto -25%;
  margin: auto;
  transform: rotate(-20deg);

  ${screenMin.xl`
    left: -5%;
  `}

  ${screenMax.m`
    left: -30%;
  `}
  ${screenMax.s`
    left: -15%;
  `}
`

export interface IllustrationProps {
  image: string
  imageDecorativeSquiggle?: boolean
  imageBackground?: boolean
}

const Illustration = ({
  image,
  imageBackground,
  imageDecorativeSquiggle = true,
}: IllustrationProps): React.ReactElement => (
  <>
    {imageDecorativeSquiggle || imageBackground ? (
      <StyledIllustrationWrapper margin={[0, 0, 'XL']}>
        {imageBackground && (
          <StyledBlobIllustration path="textures/blob-white-1" width={400} />
        )}

        {imageDecorativeSquiggle && (
          <StyledDetailIllustration
            path="textures/squiggle-1"
            width={200}
            widthFromM={240}
            widthFromXL={280}
          />
        )}
        <StyledIllustration path={image} width={700} stretch />
      </StyledIllustrationWrapper>
    ) : (
      <StyledIllustrationWrapperNoBackground>
        <StyledIllustration path={image} width={700} stretch />
      </StyledIllustrationWrapperNoBackground>
    )}
  </>
)

export default Illustration
